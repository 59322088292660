<template>

  <div>
    <job-edit-info
      :is-edit-job-sidebar-active.sync="isEditJobSidebarActive"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model.lazy="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isEditJobSidebarActive = true"
              >
                <span class="text-nowrap">Add Job</span>
              </b-button>
            </div>
          </b-col>

        </b-row>

        <!-- Status filters -->
        <b-form-group>

          <div class="demo-inline-spacing">
            <label class="mb-0">Status</label>
            <b-form-checkbox
              v-model.lazy="statusDraft"
            >
              Draft
            </b-form-checkbox>
            <b-form-checkbox
              v-model.lazy="statusInProgress"
            >
              In Progress
            </b-form-checkbox>
            <b-form-checkbox
              v-model.lazy="statusScheduled"
            >
              Scheduled
            </b-form-checkbox>
            <b-form-checkbox
              v-model.lazy="statusCompleted"
            >
              Completed
            </b-form-checkbox>
          </div>
        </b-form-group>

      </div>

      <b-table
        ref="refJobListTable"
        class="position-relative"
        :items="fetchJobs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Alias -->
        <template #cell(alias)="data">
          <b-link
            :to="{ name: 'jobs-view', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-wrap alias-column"
          >
            {{ data.item.alias }}
          </b-link>
          <small class="text-muted">{{ data.item.jobTemplate.alias }}</small>
        </template>

        <!-- Column: Scheduled -->
        <template #cell(scheduled)="data">
          <div class="text-nowrap">
            <span class="text-capitalize">{{ data.item.scheduled ? toHumanDate(data.item.scheduled) : 'N/A' }}</span>
          </div>
        </template>

        <!-- Column: Progress -->
        <template #cell(progressPercentage)="data">
          <b-progress
            v-model="data.item.progressPercentage"
            max="100"
          />
        </template>

        <!-- Column: Driver -->
        <template
          #cell(driver)="data"
        >
          <template v-if="data.item.driver !== undefined">
            <b-link
              :to="{ name: 'users-view', params: { id: data.item.driver.id } }"
              class="font-weight-bold d-block text-wrap alias-column"
            >
              <span>{{ data.item.driver.fullName }}</span>
            </b-link>
          </template>
          <span v-else>N/A</span>
        </template>

        <!-- Column: Customer -->
        <template
          #cell(customer)="data"
        >
          <template v-if="data.item.customer !== undefined">
            <b-link
              :to="{ name: 'customers-view', params: { id: data.item.customer.id } }"
              class="font-weight-bold d-block text-wrap alias-column"
            >
              <span>{{ data.item.customer.fullName }}</span>
            </b-link>
            <small class="text-muted">{{ data.item.customer.company === true ? data.item.customer.companyName : '' }}</small>
          </template>
          <span v-else>N/A</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveJobStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveJobStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'jobs-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalJobs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
  BProgress, BButton, BFormInput, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import toHumanDate from '@/libs/utils/general/dates'
import JobEditInfo from '@/views/job/jobs-edit/JobEditInfo.vue'
import useJobsList from './useJobsList'
import jobStoreModule from '../jobStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    BButton,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    JobEditInfo,

    vSelect,
  },
  setup() {
    const isEditJobSidebarActive = ref(false)
    const JOB_APP_STORE_MODULE_NAME = 'app-job'

    // Register module
    if (!store.hasModule(JOB_APP_STORE_MODULE_NAME)) store.registerModule(JOB_APP_STORE_MODULE_NAME, jobStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JOB_APP_STORE_MODULE_NAME)) store.unregisterModule(JOB_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Pending', value: 'status.pending' },
      { label: 'Verified', value: 'status.verified' },
      { label: 'Blocked', value: 'status.blocked' },
    ]

    const {
      fetchJobs,
      tableColumns,
      perPage,
      currentPage,
      totalJobs,
      dataMeta,
      perPageOptions,
      searchQuery,
      statusDraft,
      statusScheduled,
      statusInProgress,
      statusCompleted,
      sortBy,
      isSortDirDesc,
      refJobListTable,
      refetchData,

      // UI
      resolveJobStatusVariant,
      resolveJobStatusText,

    } = useJobsList()

    return {
      fetchJobs,
      tableColumns,
      perPage,
      currentPage,
      totalJobs,
      dataMeta,
      perPageOptions,
      searchQuery,
      statusDraft,
      statusScheduled,
      statusInProgress,
      statusCompleted,
      sortBy,
      isSortDirDesc,
      refJobListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveJobStatusVariant,
      resolveJobStatusText,
      toHumanDate,
      isEditJobSidebarActive,
      statusOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.alias-column {
  max-width: 200px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
